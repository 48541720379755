import React from 'react'

const VideoPlayer = ({
    videoType = 'video/mp4',
    videoUrl,
    onClose,
}) => {
    if (!videoUrl) return null;
    return (
        <>
            <div className='videoPlayerContainer'>
                <video
                    controls

                    autoPlay

                    controlsList='nodownload'
                >
                    <source
                        src={videoUrl}
                        type={videoType}
                    />
                </video>
                <button
                    type='button'
                    onClick={onClose}
                >
                    &times;
                </button>
            </div>

        </>

    )
}

export default VideoPlayer