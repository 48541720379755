import { useEffect } from "react"

/* 
gtag('event', 'page_view', {
        'page_title': document.title, // Naslov trenutne stranice
        'page_location': url,         // URL trenutne stranice
        'page_path': url              // Putanja trenutne stranice
    });
*/

function generateUniqueId() {
    return 'id-' + Math.random().toString(36).substr(2, 16);
}

const getUserId = () => {
    let id;
    if (typeof window !== 'undefined') {
        const savedId = localStorage?.getItem?.('user_id');
        if (savedId) id = savedId;
    };

    if (!id) id = generateUniqueId();

    if (typeof window !== 'undefined') {
        localStorage?.setItem?.('user_id', id)
    }

    return id;
}




const TrackUrl = () => {

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }

            const dataObj = {
                'page_title': document?.title,
                'page_location': window.location.pathname,
                'page_path': window.location.pathname,
                'userID': getUserId()

            };
            console.log('sending gtag data ...', dataObj);

            gtag('event', 'page_view', dataObj);
        }
    }, [(typeof window !== 'undefined' ? window?.location?.pathname : undefined)])

    return (
        null
    )
}

export default TrackUrl